// Returns ========================================================================
html {
    scroll-behavior: smooth;
}

#returns {
    .returnBox {
        background-color: #f6f6f6;
        border-radius: 12px;
        padding: 40px;
    }

    h3 {
        margin-top: 2.5rem;
    }
}

// Terms and Conditions ===========================================================
#terms-and-conditions {
    main {
        h1  {
            margin-bottom: rem(32px);
        }
    
        h3 {
            font-weight: $font-weight-bold;
        }

        a {
            color: #337ab7;
        }
    }
}

// Privacy Policy =================================================================
#privacy-policy {
    main {
        h1  {
            margin-bottom: rem(32px);
        }

        h3 {
            font-weight: $font-weight-bold;
            margin-bottom: rem(24px);
            margin-top: rem(24px);
        }

        h4 {
            font-weight: $font-weight-bold;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        a {
            color: #337ab7;
        }
    }
}

// Order Modification =============================================================
#order-modification {
    main {
        h1  {
            margin-bottom: rem(52px);
        }
    
        h3 {
            font-weight: $font-weight-bold;
            margin-top: rem(44px);
        }
    }
}

// Sizing Information ============================================================
#sizing-information {
    main {
        h1  {
            margin-bottom: rem(32px);
        }
    }
}

// About US =====================================================================
#about-us {
    main {
        h1  {
            margin-bottom: rem(32px);
        }
    }
}

// Contact Us ========================================================================
.contact-us-signup-message {
    position: relative;

    * {
        text-align: center;
    }

    .close {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 1;
    }

    .icon-close {
        width: rem(20px);
        height: rem(20px);
    }

    h4 {
        font-size: 1.5rem;
        font-weight: 700;
    }
}

#contact-us-landing-page button.btn.disabled {
    pointer-events: none;
}

#contact-us-landing-page .self-service-tools {
    margin: 0 0 rem(20px);
}

#contact-us-landing-page .self-service-tools>div .self-service-tools__box.title {
    color: #66b6d1;
    font-weight: 700;
}

#contact-us-landing-page .self-service-tools>div .self-service-tools__box {
    padding: 0 16px;
}

#contact-us-landing-page .self-service-tools__box:not(.title) {
    text-align: center;
}

#contact-us-landing-page .self-service-tools__box__image img {
    width: auto;
    height: rem(75px);
    margin-bottom: rem(10px);
}

#contact-us-landing-page .self-service-tools__box__text {
    line-height: 28px;
    margin-bottom: 15px;
    color: $gray-800;
}

#contact-us-landing-page .self-service-tools__box__link__button {
    color: $white;
    text-transform: uppercase;
    text-decoration: none;
    height: 60px;
    line-height: 60px;
    width: 100%;
    background: $softstretch;
    border-radius: 6px;
    display: inline-block;
    word-break: break-all;
}

#contact-us-landing-page .self-service-tools-info p {
    line-height: 1.75rem;
}

.contact-us-tabs {
    width: 100%;
}

#contact-us-landing-page .nav-tabs, #contact-us-landing-page .tab-content {
    position: relative;
	width: 100%;
}

#contact-us-landing-page .nav-tabs {
    width: 100%;
    list-style: none;
    border: none;
    margin: 0;
    z-index: 2;
}

#contact-us-landing-page .nav-item.active {
    border-top: 2px solid #0397d5;
    -webkit-box-shadow: 3px -9px 10px #ddd;
    box-shadow: 3px -9px 10px #ddd;
    z-index: 1;
    padding: 0!important;
}

#contact-us-landing-page .nav-item.active, #contact-us-landing-page .nav-link, #contact-us-landing-page .nav-link .btn {
    color: #000!important;
}

#contact-us-landing-page .nav-item, .more-info-content .col-md-4 {
    width: 33.3333%;
}

#contact-us-landing-page .nav-item {
    padding: 0;
    border-top: 2px solid #fff;
    height: 66px;
}

#contact-us-landing-page .nav-tabs .nav-item {
    margin-bottom: -1px;
}

#contact-us-landing-page .nav-tabs>li.active>button, #contact-us-landing-page .nav-tabs>li.active>button:focus, #contact-us-landing-page .nav-tabs>li.active>button:hover {
    background-color: #fff;
    border: 1px solid;
    border-color: #ddd #ddd transparent;
}

#contact-us-landing-page .nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: none;
    border-radius: 0;
}

#contact-us-landing-page .nav-link {
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    background-color: #f0f0f0;
    padding: 16px 14px!important;
    border-radius: 0;
    border: none!important;
    line-height: 31px;
    height: 100%;
    margin: 0;
}

#contact-us-landing-page .get-in-touch__description {
    border-bottom: none;
    padding-bottom: 0;
}

#contact-us-landing-page .more-info-content {
    margin: 0 auto 40px!important;
}

.contact-us-tabs .tab-pane .col-md-12, .more-info-content {
    max-width: 95%;
}

#contact-us-landing-page .get-in-touch__description__box__header {
    font-weight: 700;
    margin: 10px 0 0;
    text-transform: uppercase;
    color: #404040;
}

#contact-us-landing-page .get-in-touch__description__box__list {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
}

#contact-us-landing-page .get-in-touch__description__box__list li {
    margin-bottom: 0;
}

#contact-us-landing-page .get-in-touch__description__box__list__header {
    font-weight: 700;
}

#contact-us-landing-page .get-in-touch__description__box {
    width: 100%;
}

.contact-us-tabs a,
.nanorep-answer-container a {
    text-decoration: underline;
}

#contact-us-landing-page .self-service-tools>div .self-service-tools__box.title:after {
    background: url("/assets/svg-icons/arrowDown.svg") no-repeat 0 0;
    width: 10px;
    height: 6px;
    content: "";
    margin-top: 17px;
}

#contact-us-landing-page .self-service-tools>div.active .self-service-tools__box.title:after {
    background: url("/assets/svg-icons/arrowUp.svg") no-repeat 0 0;
}

.container a:not(.btn-primary):not(.btn-outline-primary):not(.quickview):not(.btn-link):not(.nav-link) {
    color: #404040;
}

#contact-us-landing-page .get-in-touch__chat .btn.disabled .fs-it-btn-vertical-line {
    background-color: #d0011b;
}

#contact-us-landing-page .get-in-touch__chat .btn .fs-it-btn-vertical-line {
    height: 12px;
    width: 12px;
    background-color: #44ae99;
    display: inline-block;
    border-radius: 50%;
    vertical-align: unset;
}

#contact-us-landing-page .get-in-touch__chat .btn.start-chat .fs-it-btn-vertical-line {
    margin-right: map-get($spacers, 1);
}

#contact-us-landing-page .nav-link .fa, #contact-us-landing-page .nav-link span {
    display: inline-block;
}


@media only screen and (max-width: 768px) {
    .colorPage .colorpageHeroSlider .contentWrapper {
        background-position: center -45px !important;
        background-size: 195% !important;
    }
}

@media only screen and (max-width: 767px) {
    /* breakpoint_3 */
    body:after {
        content: 'breakpoint_3';
        visibility: hidden;
        position: absolute;
        height: 0;
        bottom: 20px;
    }

    /*This module is use only for the colorpage - bottom at the homepage*/
    .shopByColor .shopByColorOneUp1.colorpageonly .contentColumn {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/shop_by_colors-mobile.jpg") no-repeat center top;
        background-size: 100% 100% !important;
        padding-bottom: 45.75% !important;
    }

    .shopByColor .shopByColorMobile.colorpageonly .contentColumn {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/shopByColorMobile.png") no-repeat center top;
        background-size: 100% 100% !important;
        padding-bottom: 15% !important;
        display: block;
    }

    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module {
        padding: 0 15px;
        /* Slider */
    }

    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .contentColumn {
        padding-bottom: 10.00002%;
    }

    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .moduleSlide .contentWrapper {
        padding-bottom: 131.25%;
        display: block !important;
    }

    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .column1,
    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .slide1 .contentWrapper {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/sixUp-image1-mobile.png") no-repeat center top;
        background-size: 100% 100% !important;
    }

    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .column2,
    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .slide2 .contentWrapper {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/sixUp-image2-mobile.png") no-repeat center top;
        background-size: 100% 100% !important;
    }

    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .column3,
    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .slide3 .contentWrapper {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/sixUp-image3-mobile.png") no-repeat center top;
        background-size: 100% 100% !important;
    }

    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .column4,
    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .slide4 .contentWrapper {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/sixUp-image4-mobile.png") no-repeat center top;
        background-size: 100% 100% !important;
    }

    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .column5,
    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .slide5 .contentWrapper {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/sixUp-image5-mobile.png") no-repeat center top;
        background-size: 100% 100% !important;
    }

    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .column6,
    .shopByColor .shopByColorSixUp.colorpageonly.sixUp-module .slide6 .contentWrapper {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/sixUp-image6-mobile.png") no-repeat center top;
        background-size: 100% 100% !important;
    }

    .espanolPage .shopByColor .shopByColorOneUp1.colorpageonly .contentColumn {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/shop_by_colors-mobile__es.jpg") no-repeat center top;
        background-size: 100% 100% !important;
    }

    .espanolPage .shopByColor .shopByColorMobile.colorpageonly .contentColumn {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/homepage/shopByColorMobile__es.png") no-repeat center top;
        background-size: 100% 100% !important;
        padding-bottom: 21.25% !important;
    }

    /* Color Page Slider */
    .colorPage .colorpageHeroSlider .contentWrapper {
        height: 350px;
        background-position: center -25px !important;
        background-size: 242% !important;
    }

    .colorPage .colorpageHeroSlider .contentWrapper span {
        width: 100%;
        max-width: 286px;
        height: 0;
        padding-bottom: 23.77622%;
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/colorspage/combo-color-made-easy-mobile.png") no-repeat center top;
    }

    .colorPage .tabbedNav .slick-slide {
        padding: 0 200px;
    }

    .colorPage .tabbedNav .slick-prev,
    .colorPage .tabbedNav .slick-next {
        width: 200px;
    }

    /* Spanish Color Page */
    .espanolPage .colorPage .colorpageHeroSlider .contentWrapper span {
        max-width: 374px;
        padding-bottom: 16.84492%;
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/colorspage/combo-color-made-easy-mobile__es.png") no-repeat center top;
        background-size: 100% !important;
    }
}

@media only screen and (max-width: 650px) {
    .colorPage .tabbedNav .slick-slide {
        padding: 0 60px;
    }

    .colorPage .tabbedNav .slick-prev,
    .colorPage .tabbedNav .slick-next {
        width: 60px;
    }

    .colorPage .tabbedContentWrapper .shopYourColor li {
        width: 50%;
        max-width: 50%;
    }

    .colorPage .tabbedContentWrapper .shopYourColor img {
        max-width: 100%;
    }

    .colorPage .tabbedContentWrapper .matchYourColor .contentRow .col-md-9 .contentWrapper {
        padding-bottom: 125%;
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/colorspage/finish-your-look-mobile.gif") no-repeat center top;
        background-size: 100% !important;
    }

    .colorPage .tabbedContentWrapper .matchYourColor .contentRow .colorPageButton:first-child {
        margin-right: 0;
    }

    .espanolPage .colorPage .tabbedContentWrapper .matchYourColor .contentRow .col-md-9 .contentWrapper {
        background: url("https://www.uniformadvantage.com/on/demandware.static/-/Sites-UA-Library/default/dwd2093426/colorspage/finish-your-look-mobile__es.gif") no-repeat center top;
    }
}

@media (min-width: 992px){
	.sidebar-navigation {
		margin-top: 3.5rem;
	}
	
	#contact-us-landing-page .self-service-tools {
		margin-bottom: rem(50px);
	}
	
	#contact-us-landing-page .self-service-tools>div .self-service-tools__box {
		padding: rem(20px) rem(20px) rem(80px);
		height: 100%;
		position: relative;
	}
	
	#contact-us-landing-page .self-service-tools__box {
		border: 1px solid $cart-grey;
		border-radius: rem(6px);
	}
	
	/*.d-md-none {
		display: none!important;
	}*/
	
	#contact-us-landing-page .self-service-tools__box__link {
		position: absolute;
		bottom: rem(20px);
		width: calc(100% - 40px);
	}
	
	.content-page {
		padding-bottom: 6.25rem;
	}

}

@media only screen and (max-width: 1024px) {
    .colorPage .tabbedContentWrapper .matchYourColor .contentRow .col-md-3 {
        min-width: initial;
    }
	
	#contact-us-landing-page .page-title {
		font-size: 1.375rem;
		color: $softstretch;
	}
	
	#contact-us-landing-page .self-service-tools__box__link__button {
		max-width: 255px;
		height: 40px;
		line-height: 40px;
	}

}

@media only screen and (max-width: 600px) {

    .imageTextWrapLeft,
    .imageTextWrapRight {
        float: none;
        display: block;
        margin: 0 auto 10px;
    }
}

@media only screen and (max-width: 480px) {
    /* breakpoint_4 */
    body:after {
        content: 'breakpoint_4';
        visibility: hidden;
        position: absolute;
        height: 0;
        bottom: 20px;
    }

    .two-columns,
    .three-columns {
        column-count: 1;
    }

    .brandsSortMenu a {
        width: 30px;
        text-align: center;
        height: 40px;
    }
}

@media only screen and (max-width: 400px) {
    .colorPage .colorpageHeroSlider .contentWrapper {
        background-size: 270% !important;
    }
}

#contact-us-landing-page .nav-tabs,#contact-us-landing-page .tab-content {
    width: 100%;
}

#contact-us-landing-page .nav-link .btn {
    padding: 0;
    background: transparent!important;
}

#contact-us-landing-page .btn:not(:disabled):not(.disabled):not(.btn-icon--action):not(.btn-link):not(.btn-custom):focus {
    background-color: transparent!important;
    border: none!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}

#contact-us-landing-page .nav-link .fa,#contact-us-landing-page .nav-link span {
    display: inline-block;
    vertical-align: middle;
}

#contact-us-landing-page .nav-item {
    padding: 0;
    border-top: 2px solid $white;
    height: rem(66px);
}

#contact-us-landing-page .nav-item,.more-info-content .col-md-4 {
    width: 33.3333%;
}

#contact-us-landing-page .nav-item:last-child .nav-link {
    margin: 0;
    border-right: 1px solid #f0f0f0!important;
}

#contact-us-landing-page .nav-item.active {
    border-top: 2px solid $accessi-be-blue;
    -webkit-box-shadow: 3px -9px 10px $gray-500;
    box-shadow: 3px -9px 10px $gray-500;
    z-index: 11;
    padding: 0!important;
}

#contact-us-landing-page .nav-tabs,#contact-us-landing-page .tab-content {
    position: relative;
}

.contact-us-landing-page-tabs {
    margin-top: 2em;
}

.contact-us-landing-page-tabs .tab-content {
    z-index: 14;
    padding: 5em 8em;
    margin: -2px 0 0;
    width: 100%;
    background: $white;
}

#contact-us-landing-page .nav-tabs>li.active>a,#contact-us-landing-page .nav-tabs>li.active>a:focus,#contact-us-landing-page .nav-tabs>li.active>a:hover {
    background-color: $white;
    border: 1px solid;
    border-color: $gray-500 $gray-500 transparent;
}

#contact-us-landing-page .nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: none;
    border-radius: 0;
}

#contact-us-landing-page hr {
    margin: 3em 3em 1em;
}

.call-tab-mobile {
    display: none!important;
}

.contact-us-tabs .tab-pane .col-md-12,.more-info-content {
    max-width: 95%;
}

.contact-us-tabs .tab-pane .col-md-12 {
    margin: 0 auto;
}

.more-info-content {
    margin: 0 auto 40px!important;
}

#contact-us-landing-page .get-in-touch__description {
    border-bottom: none;
    padding-bottom: 0;
}

#contact-us-landing-page .get-in-touch__description__box {
    width: 100%;
}

#contact-us-landing-page .collapsible-sm {
    width: calc(25% - 11.44px);
    margin: 0 rem(15px) rem(15px) 0;
    min-width: rem(147px);
}

#contact-us-landing-page .collapsible-sm:last-child {
    margin-right: 0;
}

#contact-us-landing-page .self-service-tools__box__link__button {
    font-size: rem(14px);
}

@media only screen and (max-width: 1024px) {
    #contact-us-landing-page .self-service-tools>div {
        border-bottom:none;
    }

    #contact-us-landing-page .self-service-tools>div:first-child {
        border-top: none;
    }
}

@media only screen and (max-width: 991px) {
    #contact-us-landing-page .self-service-tools>div .self-service-tools__box {
        padding:0 16px;
    }

    #contact-us-landing-page .collapsible-sm {
        width: 100%;
        margin: 0;
        min-width: auto;
    }

    #contact-us-landing-page .self-service-tools>div:first-child {
        border-top: 1px solid $porpoise;
    }

    #contact-us-landing-page .self-service-tools>div {
        border-bottom: 1px solid $porpoise;
    }
	#contact-us-landing-page .self-service-tools__box__link__button {
		margin-bottom: rem(20px);
	}
}

@media only screen and (max-width: 767px) {
    .contact-us-tabs .tab-pane .col-md-12,.more-info-content {
        max-width:100%;
    }
}

@media only screen and (max-width: 480px) {
    #contact-us-landing-page .nav-link {
        padding:16px 10px!important;
    }

    .more-info-content .col-md-4 {
        width: 100%;
    }
}

@media (max-width: 1024.98px) {
    #contact-us-landing-page .page-title {
        font-size:1.375rem;
        color: $softstretch;
    }
}

#contact-us-landing-page h2 {
    font-size: 1.125rem;
}

#contact-us-landing-page .self-service-tools-info p {
    line-height: 1.75rem;
}

#contact-us-landing-page .self-service-tools__box__title {
    color: $gray-800;
    font-size: 1.5rem;
    margin-bottom: rem(10px);
    font-weight: 700;
}

#contact-us-landing-page .self-service-tools>div .self-service-tools__box {
    padding: 0 rem(16px);
}

@media (max-width: 991.98px) {
    #contact-us-landing-page .self-service-tools {
        -webkit-box-shadow:0 3px 6px 0 rgba(0,0,0,.16);
        box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
        margin-left: map-get($spacers, 4) * -1;
        margin-right: map-get($spacers, 4) * -1;
    }

    #contact-us-landing-page .self-service-tools>div {
        border-bottom:1px solid $porpoise;
    }

    #contact-us-landing-page .self-service-tools>div:first-child {
        border-top:1px solid $porpoise;
    }
}

@media (min-width: 992px) {
    #contact-us-landing-page h2 {
        font-size:1.5rem;
        line-height: 20px;
    }

    #contact-us-landing-page .self-service-tools>div .self-service-tools__box {
        padding:rem(20px) rem(20px) rem(80px);
        height: 100%;
        position: relative;
    }
}

// Live Chat
#contact-us-landing-page {
    .chat-enabled {
        &.chat-offline {
            .chat-online {
                display: none !important;
            }

            .chat-offline {
                display: flex !important;
            }
        }

        &.chat-online {
            .chat-online {
                display: flex !important;
            }

            .chat-offline {
                display: none !important;
            }
        }
    }
}
