#order-confirmation {
    h1 {
        font-size: $h6-font-size;
        font-weight: $font-weight-prebold;

        @include media-breakpoint-up(md) {
            font-size: $font-size-base * 2.5;
        }
    }

    &.checkout-page {
        .card {
            &.products-summary {
                .card-body {
                    .list-group {
                        margin: 0;
                        padding: 0;
    
                        li {
                            padding-left: 0;
                            padding-right: 0;
    
                            &:first-of-type {
                                padding-top: 0;
                            }
    
                            &:last-of-type {
                                margin-bottom: 0;
                                padding-bottom: 0;
                            }
                        }
                    }

                    .product-line-item {
                        display: grid;
                        grid-gap: $spacer;
                        grid-template-columns: minmax(auto, rem(100px)) 1fr;
    
                        @include media-breakpoint-up(md) {
                            grid-template-columns: minmax(auto, rem(160px)) 1fr;
                        }
    
                        .product-info {
                            > div:nth-child(2) {
                                margin: map-get($spacers, 3) 0;
                            }
    
                            .line-item-title {
                                p {
                                    font-size: $font-size-base;
                                }
                            }
                        }
                    }

                    .product-info {
                        .font-weight-prebold {
                            color: $black;
                        }
                    }

                    .embroidery-summary {
                        @include media-breakpoint-up(md) {
                            padding-left: rem(160px) + $spacer;
                        }
                    }
                }
            }
        }
    }
}

.modal-qualtrics {
    max-width: map-get($container-max-widths, sm);

    .modal-body {
        padding: 0 1rem 1rem 1rem;

        iframe {
            border: none;
            display: block;
            outline: 0;
            height: 78vh;
            width: 100%;
        }
    }
}

.order-emb-sec {
    font-size: 0.875rem;
    margin-top: 1rem;
    text-decoration: underline;
}

.order-emb-sec a {
    width: rem(200px);
}
