.custom-quantity {
    .btn-secondary {
        border-color: $gray-500;
        background-color: $white;

        &:not(:disabled):not(.disabled) {
            &:active,
            &:hover,
            &:focus {
                border-color: $gray-500;
                background-color: $white;
            }
        }
        &.disabled {
            background-color: $gray-400;
        }

        &::before,
        &::after {
            position: absolute;
            content: '';
            display: inline-block;
            background: $body-color;
        }

        &::after {
            top: calc(50% - 1px);
            left: calc(50% - 8px);
            width: rem(16px);
            height: rem(2px);
        }

        &.quantity-decrease {
            border-right: 0;
        }

        &.quantity-increase {
            border-left: 0;

            &::before {
                right: calc(50% - 1px);
                top: calc(50% - 8px);
                width: rem(2px);
                height: rem(16px);
            }
        }
    }

    &.custom-quantity-sm {
        .btn-secondary {
            padding: rem(19px) rem(18px);
        }

        input {
            height: rem(40px);
            padding: 0;
        }
    }

    .form-control,
    input {
        color: $black;
        font-size: $font-size-xxl;
        font-weight: $font-weight-bold;
        padding: 0;
        text-align: center;
        border-right-color: transparent;
        border-left-color: transparent;

        &[readonly],
        &.readonly {
            background-color: $white;

            &:focus {
                box-shadow: none;
                outline: 0;
            }
        }
        &:active,
        &.active {
            border-color: $black;
        }
    }

    .form-control:focus,
    input:focus,
    input[type=number]:focus {
        border-color: $gray-500;
    }

    ~ .invalid-feedback {
        display: block;
    }
}

.custom-select {
    &:focus {
        box-shadow: none;
        outline: 0;
    }
}

.form-group {
    .form-control-feedback {
        display: none;
    }

    &.has-danger {
        .form-control-feedback {
            display: block;
        }
    }

    &.required label::after {
        content: "*";
        color: $black;
    }
}

.form-group {
    > .form-control-feedback {
        display: none;
        font-size: 0.8125rem;
    }

    &.has-danger,
    &.has-warning {
        > .form-control-feedback {
            display: block;
        }
    }
}

.form-check,
.form-group {
    &.custom-control-tall {
        height: 100%;
        padding-left: 0;
    
        .custom-control-label {
            border: solid 1px $cart-grey;
            border-radius: $border-radius-xs;
            display: flex;
            gap: $spacer;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            min-height: rem(120px);
            padding: rem(20px) rem(32px) rem(20px) rem(48px);
            width: 100%;
    
            &:hover {
                background-color: $gray-400;
            }

            * {
                display: block;
                margin: 0;
                pointer-events: none;
                z-index: 1;
            }

            .price {
                font-size: $font-size-base;
            }
        }
    
        .custom-control-label::before,
        .custom-control-label::after,
        .custom-control-input {
            top: 50%;
            left: rem(14px);
            transform: translateY(-50%);
        }
    
        .custom-control-input {
            &:checked ~ .custom-control-label {
                background-color: $gray-400;
            }
        }
    }
}

// Cleaning up some weird elements on input number
input[type=number] {
    appearance: none;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

// Cleaning up some weird things on Safari
input[type=date] {
    -webkit-appearance: none;
    line-height: 44px;
}
