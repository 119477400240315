body.menu-open {
    @include media-breakpoint-down(sm) {
        overflow: hidden;
    }
}

.main-menu {
    @include media-breakpoint-down(sm) {
        background-color: $trans;
        height: 100vh;
        max-height: 100%;
        position: fixed;
        left: -100%;
        top: 0;
        width: 90vw;
        z-index: 4;

        .close-button-overlay {
            background: $trans;
            border: 0;
            display: none;
            height: 100vh;
            padding: 0;
            position: fixed;
            right: 0;
            top: 0;
            width: 10vw;
            z-index: 5;
            -webkit-appearance: none;                
        }

        &::after {
            background-color: $black;
            content: '';
            height: 100%;
            opacity: 0;
            position: fixed;
            left: 0;
            top: 0;
            width: 0;
        }
        
        &.in {
            left: 0;
            transition: left 425ms cubic-bezier(0.71, 0, 0.55, 0.99);

            .close-button-overlay {
                display: block;
            }

            &::after {
                opacity: 0.5;
                width: 100%;
                transition: opacity 175ms cubic-bezier(0.71, 0, 0.55, 0.99) 75ms, width linear;
            }
        }

        &.out {
            left: -100%;
            transition: left 225ms cubic-bezier(0.71, 0, 0.55, 0.99);

            &::after {
                transition: opacity 175ms cubic-bezier(0.71, 0, 0.55, 0.99), width 1ms linear 175ms;
            }
        }
    }

    @include media-breakpoint-up(md) {
        border-bottom: 1px solid $gray-500;
        width: 100%;
    }

    .navbar {
        padding: 0;

        .nav-item {
            display: inline-block;
            margin: 0;
            padding: 0;
            position: initial;

            .nav-link {
                margin: 0;
                padding: rem(11px) $spacer;
                position: relative;
            
                &--red {
                    color: $brand-second !important;
                    font-weight: 700;
                }
            }

            .submenu-title {
                color: $black;
                font-size: $font-size-xl;
                font-weight: $font-weight-bold;
                letter-spacing: 0;
                margin: 0;
                @include media-breakpoint-down(md) {
                    font-size: rem(16px);
                    padding: rem(11px) rem(16px);
                }
            }

            .dropdown-menu {
                border: 0;
                border-radius: 0;
                margin: 0;
            }
        }

        .navbar-nav {
            ul {
                list-style-type: none;
                margin-bottom: 0;
                padding-left: 0;

                li {
                    font-size: $font-size-base;
                    margin: 0;

                    a {
                        font-size: $font-size-base;
                        line-height: normal;
                    }
                }
            }
        }

        .close-menu {
            flex: 0 0 100%;
            padding: rem(11px) $spacer;

            .close-button {
                align-items: center;
                background-color: transparent;
                border: 0;
                font-size: $font-size-xs;
                padding: 0;
                -webkit-appearance: none;
                color: $black;

                .icon {
                    margin-left: rem(5px);
                }
            }

            @include media-breakpoint-down(sm) {
                padding: rem(11px) rem(5px);
            }
        }

        @include media-breakpoint-down(sm) {
            align-content: flex-start;
            background-color: $menu-bg;
            height: 100%;
            overflow-y: auto;
            padding-bottom: 20px !important;
            width: 100%;
            z-index: 5;

            .navbar-nav {
                .menu-list-item,
                .submenu li,
                .nav-item {
                    &.account-item {
                        border: 0;
                        font-size: $font-size-xs;
                        margin-bottom: 0;
    
                        &.first {
                            padding-top: rem(29px);
                        }

                        .nav-link {
                            padding-top: map-get($spacers, 1);
                            padding-bottom: map-get($spacers, 1);
                        }
                    }

                    &:not(.account-item) {
                        .nav-link {
                            color: $black;
                            font-weight: $font-weight-bold;
                        }
                    }

                    + .nav-item {
                        &.account-item:not(.first) {
                            border: 0;
                        }
                    }

                    .dropdown-menu-bg {
                        display: none;
                    }

                    .dropdown-menu {
                        background-color: $trans;
                        box-shadow: none;
                        display: block;
                        margin: 0;
                        opacity: 1;
                        padding: 0;
                        visibility: visible;
                        width: 100%;

                        .dropdown-container {
                            max-height: 100%;
                            overflow-x: visible;

                            .menu-list {
                                flex: 0 0 100%;
                                margin:0;

                                .menu-list-item {
                                    padding: 0;
                                    width: 100%;

                                    a,
                                    .submenu-title {
                                        display: block;
                                        margin: 0;
                                        padding: rem(11px) $spacer;
                                        position: relative;
                                    }

                                    .submenu-title {
                                        color: $black;
                                        font-size: $font-size-base;
                                        line-height: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            background-color: $white;
            padding: 3px 0 0;
            position: static;
            text-align: center;
            width: 100%;

            .navbar-nav {
                ul {
                    li {
                        font-size: $font-size-xs;
                        padding: 1px 0;

                        a {
                            font-size: $font-size-xs;
                        }
                    }
                }

                > .nav-item {
                    break-inside: avoid;
                    margin: 0 rem(15px);
                    padding: rem(15px) 0;

                    @include media-breakpoint-up(xl) {
                        margin: 0 rem(30px);
                    }

                    .nav-link {
                        border-bottom: rem(3px) solid $white;
                        color: $black;
                        padding: 0;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }

                    .submenu-title {
                        font-size: $font-size-xs;
                        line-height: normal;
                        padding: 1px 0;
                    }

                    .dropdown-menu,
                    .dropdown-menu-bg {
                        display: block;
                        opacity: 0;
                        transition: opacity 0.25s, margin-top 0.25s, visibility 0s 0.3s;
                        visibility: hidden;
                    }

                    .dropdown-menu-bg {
                        background-color: $light-gray;
                        box-shadow: $shadow3;
                        margin-top: -1px;
                        position: absolute;
                        left: 0;
                        top: 100%;
                        width: 100%;
                        z-index: 999;
                    }

                    .dropdown-menu {
                        background-color: $trans;
                        box-shadow: none;
                        padding-bottom: rem(40px);
                        top: calc(100% + rem(15px));
                        width: max-content;

                        .dropdown-container {
                            max-height: calc(100vh - 285px);
                            overflow-y: auto;
        
                            &.has-custom-scrollbar {
                                &::-webkit-scrollbar-thumb {
                                    box-shadow: inset 0 0 4px 4px $gray-700;
        
                                    &:hover {
                                        box-shadow: inset 0 0 4px 4px $gray-800;
                                    }
                                }
                            }

                            .menu-list {
                                margin: rem(25px) auto 0;
                                max-width: 1132px;

                                &.d-md-flex {
                                    flex-wrap: wrap;
                                    gap: rem(32px);
                                    width: auto;
                                }
                            }
                        }
                    }

                    &.nav-item-columns {
                        .dropdown {
                            position: initial;

                            .dropdown-menu {
                                margin: 0 auto;
                                top: 100%;
                                width: 100%;

                                .dropdown-container {
                                    margin: 0 auto;
                                    padding: 0 map-get($spacers, 6);
                                    width: max-content;
                                }
                            }
                        }

                        &.four-columns {
                            .menu-list {
                                columns: 4;
                                column-gap: rem(32px);
                                max-width: rem(840px);
                                width: max-content;

                                li {
                                    break-inside: avoid;
                                }
                            }
                        }
                    }

                    &:hover {
                        .nav-link {
                            border-color: $black;

                            &--red {
                                border-color: $brand-second;
                            }
                        }

                        .dropdown-menu,
                        .dropdown-menu-bg {
                            opacity: 1;
                            transition: opacity 0.25s, margin-top 0.25s;
                            visibility: visible;
                        }

                        &.nav-item-columns {
                            .dropdown {
                                .dropdown-menu {
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-nav {
            > .nav-item {
                .dropdown-menu {
                    .dropdown-container {
                        .menu-list {
                            &.d-md-flex {
                                @include media-breakpoint-up(xl) {
                                    width: rem(840px);
                                }

                                @include media-breakpoint-between(md, llg) {
                                    &.menu-list-columns-1 {
                                        .menu-list-item {
                                            flex-basis: 100%;
                                        }
                                    }

                                    &.menu-list-columns-2 {
                                        .menu-list-item {
                                            flex-basis: calc(50% - rem(32px));
                                        }
                                    }

                                    &.menu-list-columns-3 {
                                        .menu-list-item {
                                            flex-basis: calc(33.333333% - rem(32px));
                                        }
                                    }
                                }

                                @include media-breakpoint-up(llg) {
                                    .menu-list-item {
                                        flex-basis: calc(25% - rem(32px));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .subMenu_header{
        display: none;
    }

    @include media-breakpoint-down(sm) {
        .subMenu_header{
            display: block;
            font-weight: 700;
            text-align: center;
            font-size: rem(18px);
            border-top: 1px solid $gray-500;
            color: $black !important;
            padding: rem(12px) 0 !important;
            text-transform: capitalize;
        }

        .submenu {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            width: 90vw;
            height: 100vh;
            max-height: 100%;
            border: 0;
            -webkit-transition: left .5s ease-out;
            transition: left .5s ease-out;
            background: $gray-400;
            display: block;
            overflow-y: auto;
            z-index: 6;
        }

        .submenu-open{
            left: 0;
        }
    
        .pull-left {
            float: left;
        }
    
        .pull-right {
            float: right;
        }
    
        .menu-toggleable-left .close-menu button {
            font-size: rem(14px);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .btn-back {
            background-color: transparent;
        }
    
        .main-menu .navbar .navbar-nav .nav-item.account-item.first {
            padding-top: 0;
        }
    
        .main-menu .navbar .navbar-nav ul li a {
            font-size: rem(14px);
        }
    
        .submenu-arrow-right:before {
            width: rem(10px);
            height: rem(10px);
            background: $svg-arrow-right no-repeat;
            background-size: 100% 100%;
            border: 0;
            position: absolute;
            right: rem(13px);
            top: 0;
            bottom: 0;
            margin: auto;
            content: "";
            display: block;
        }

        .btn-back .icon-arrowLeft {
            float: left;
            margin: rem(9px) rem(5px) 0 0;
            width: rem(7px);
            height: rem(12px);
        }

        .menu-list-item a{
            display: block;
            margin: 0;
            padding: rem(11px) rem(16px);
            position: relative;
        }
    }

    .subMenu_header{
        display: none;
    }

    @include media-breakpoint-down(sm) { 
        .subMenu_header{
            display: block;
            font-weight: 700;
            text-align: center;
            font-size: rem(18px);
            border-top: 1px solid $gray-500;
            color: $black !important;
            padding: rem(12px) 0 !important;
            text-transform: capitalize;
        }
        .submenu {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            width: 90vw;
            height: 100vh;
            max-height: 100%;
            border: 0;
            -webkit-transition: left .5s ease-out;
            transition: left .5s ease-out;
            background: $gray-400;
            display: block;
            overflow-y: auto;
            z-index: 6;
        }
        .submenu-open{
            left: 0;
        }
    
        .pull-left {
            float: left;
        }
    
        .pull-right {
            float: right;
        }
    
        .menu-toggleable-left .close-menu button {
            font-size: rem(14px);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .btn-back {
            background-color: transparent;
        }
    
        .main-menu .navbar .navbar-nav .nav-item.account-item.first {
            padding-top: 0;
        }
    
        .main-menu .navbar .navbar-nav ul li a {
            font-size: rem(14px);
        }
    
        .submenu-arrow-right:before {
            width: rem(10px);
            height: rem(10px);
            background: $svg-arrow-right no-repeat;
            background-size: 100% 100%;
            border: 0;
            position: absolute;
            right: rem(13px);
            top: 0;
            bottom: 0;
            margin: auto;
            content: "";
            display: block;
        }

        .btn-back .icon-arrowLeft {
            float: left;
            margin: rem(9px) rem(5px) 0 0;
            width: rem(7px);
            height: rem(12px);
        }

        .menu-list-item a{
            display: block;
            margin: 0;
            padding: rem(11px) rem(16px);
            position: relative;
        }
    }
}

.shop-menu-list{
    @include media-breakpoint-down(sm) {
        position: fixed;
        left:-100%;
        top: 0;
        bottom: 0;
        width: 90vw;
        height: 100vh;
        max-height: 100%;
        border: 0;
        -webkit-transition: left .5s ease-out;
        transition: left .5s ease-out;
        background: $gray-400;
        display: block;
        overflow-y: auto;
        z-index: 6;
    }
}
.menuListOpen{
    left: 0;   
}

.profile-submenu-title{
    color: $black;
    font-size: rem(14px);
    line-height: normal;
    display: block;
    margin: 0;
    padding: rem(11px) rem(16px);
    position: relative;
    font-weight: 700;
    letter-spacing: 0;
    border-top: 1px solid $gray-500;
}

.dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
    @include media-breakpoint-down(md) {
        color: $gray-800;
        text-decoration: none;
        background-color: transparent;
    }
}

.profileMenus{
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    width: 90vw;
    height: 100vh;
    max-height: 100%;
    border: 0;
    transition: left 0.5s ease-out;
    background: $gray-400;
    overflow-y: auto;
    z-index: 6;
}
.profileMenu-open{
    left: 0;
}

.profileMenus .dropdown-item.top-category {
    font-weight: 700;
    padding-left: 0;
    text-align: center;
    font-size: rem(18px);
}
.profileMenus .dropdown-item {
    border-top: 1px solid $gray-500;
    padding: 0 0 0 rem(16px);
    margin-bottom: 0;
    white-space: normal;
}
.profileMenus .dropdown-item .user-message {
    color: $gray-800;
}

.profileMenus .dropdown-item a {
    display: block;
    padding: rem(11px) rem(16px) rem(11px) 0;
    position: relative;
}

.icon[class*=accountDarkV2] {
    display: block;
}

#navbarSupportedContent li{
    @include media-breakpoint-down(sm) {
        border-top: 1px solid $gray-500;
        position: relative;
    }     
}

#navbarSupportedContent .nav-item+.nav-item.account-item:not(.first) {
    border: 0;
}
