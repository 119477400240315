.modal-content {
    background-clip: padding-box;
}

.modal {
    &.fade.upslide .modal-dialog {
        transform: translate3d(0, 100vh, 0);
    }

    &.show.upslide .modal-dialog {
        transform: translate3d(0, 0, 0);
    }
}

.modal-dialog {
    margin-left: auto;
    margin-right: auto;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
    border-top: $modal-border-top;
    box-sizing: content-box;

    .modal-title {
        font-size: $h6-font-size;
        font-weight: $font-weight-normal;
        letter-spacing: $letter-spacing-sm;
        line-height: $line-height-xs;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        padding: $modal-header-padding-xl;
    }

    .close {
        width: $modal-header-size;
        height: $modal-header-size;
        position: absolute;
        padding: 0;
        right: $modal-header-close-position;
        top: $modal-header-close-position;
        opacity: 1;
        z-index: 2;

       
    }
}

.modal-body {
    padding: $modal-inner-padding;

    @include media-breakpoint-up(md) {
        padding: $modal-inner-padding-xl;
    }
}

.modal-footer {
    gap: map-get($spacers, 4);
    padding: $modal-footer-padding;

    > * {
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        gap: map-get($spacers, 6);
        padding: $modal-footer-padding-xl;
    }
}

// Modal sizing portal
.sizing-modal {
    @include media-breakpoint-up(md) {
        max-width: 600px !important;
    }

    .modal-footer {
        gap: 1rem;

        .btn {
            width: 100%;
        }

        @include media-breakpoint-up(sm) {
            gap: 0;

            .btn {
                width: calc(50% - 1rem);
            }
        }
    }
}

.isModalclosed {
    display: none;
}
