@import "./utilities/swatch";
@import "./components/productFit";

.product-details {
    .product-images {
        figure {
            background-color: $gray-400;
            display: block;
        }

        .pdp-single-image {
            figure {
                margin-bottom: map-get($spacers, 2);

                &.no-image {
                    background-color: $trans;
                }

                img {
                    display: block;
                    margin: 0 auto;

                    @include media-breakpoint-up(md) {
                        max-width: 50%;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            .slider {
                display: flex;
                flex-wrap: wrap;
                gap: rem(12px);

                .slide {
                    width: calc(50% - #{rem(6px)});
                }
            }
        }

        .slick-slide {
            box-sizing: border-box;
        }
    }

    @include media-breakpoint-up(md) {
        .product-title-block {
            margin: 0 auto;
            max-width: rem(400px);
        }
    }

    .product-details-column {
        margin: 0 auto;
        max-width: rem(400px);

        .custom-select {
            height: rem(44px);
        }
    }

    .select-search-wrap {
        label.custom-select {
            overflow: hidden;
            padding-top: 0.7rem;
            white-space: nowrap;

            &:hover {
                cursor: pointer;
            }
        }

        div {
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: auto;
            border: 1px solid rgb(221, 221, 221);
            border-top: none;
        }

        *.hidden {
            display: none;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                white-space: nowrap;
                cursor: pointer;
                padding: 0.5rem 0.3rem;

                &:hover {
                    background-color: lightskyblue;
                }

                &.hidden {
                    display: none;
                }
            }
        }
    }

    .pbeSelectionWrap {
        .pbe-select-title {
            font-size: 0.75rem;
            letter-spacing: 1.2px;
            line-height: 1.75;
        }

        .pbe-filter {
            height: rem(44px);
        }

        &.hidden, *.hidden {
            display: none;
        }
        
        .pbeSelection {
            transition: all ease-in-out 0.15s;

            .list {
                max-height: 160px;
                overflow-y: hidden;
                overflow-x: hidden;

                &.scroll {
                    overflow-y: auto;
                }
            }
        }

        .pbeSelectionToggle {    
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .pbeSelectionToggle, .pbeSelectionToggle > * {
            cursor: pointer;
        }
    }

    .product-number {
        color: $stylegey;
        font-size: $font-size-xs;
    }

    .product-name {
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
        letter-spacing: $letter-spacing-base;

        @include media-breakpoint-up(md) {
            font-size: $font-size-base;
            letter-spacing: $letter-spacing-xs;
        }
    }

    .product-price {
        color: $black;
        font-size: $font-size-base * 1.375;
        font-weight: $font-weight-prebold;
        line-height: $line-height-xs;

        @include media-breakpoint-up(md) {
            font-size: $font-size-base * 1.625;
        }
    }

    .price-add-ons {
        font-size: $font-size-xs;
        font-weight: $font-weight-medium;

        .clearfix {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
                overflow: auto;
                white-space:initial;
            }
        }
    }

    .rating {
        margin-right: 2px;

        .fas {
            font-size: $font-size-xs;
        }
    }

    @include productFit;

    .product-description select {
        background-color: $gray-400 !important;
        font-size: $font-size-xs;
    }

    .color-swatches {
        display: flex;
        flex-wrap: wrap;
        gap: rem(8px);

        .swatch-circle {
            @include swatch(30px, $white);
            margin: 4px !important;
            padding: 0;
        }
    }

    .size-attribute-title {
        &.label {
            font-size: $font-size-xxs;
            letter-spacing: $letter-spacing-lg;
            line-height: $line-height-base;

            span {
                color: $gray-800;
                font-size: $font-size-xs;
                font-weight: $font-weight-normal;
                letter-spacing: 0;
                margin-right: map-get($spacers, 2);
                text-transform: none;
            }
        }
    }

    .size-attribute-content {
        display: grid;
        flex-wrap: wrap;
        gap: rem(8px);
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;

        button {
            background-color: $gray-400;
            padding: rem(11px) rem(5px);
            position: relative;
            width: 100%;

            &:not(:disabled):not(.disabled) {

                &.active,
                &:active,
                &:focus {
                    border-color: $black;
                }

                &:focus {
                    color: inherit !important;
                    background-color: $gray-400 !important;
                    box-shadow: inherit !important;
                }
            }

            &.disabled {
                color: $gray-600;
                overflow: hidden;
                position: relative;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    border-top: 1px solid $gray-600;
                    left: 50%;
                    top: 50%;
                    transform: rotate(-33deg) translate(-50%, -50%);
                    transform-origin: 0 0;
                    width: 100%;
                }

                &:after {
                    transform: rotate(33deg) translate(-50%, -50%);
                }
            }
        }

        &.unselected {
            button:not(.disabled) {
                border: $border-danger;
            }
        }
    }

    .accordion-cards {
        .card-body {
            font-size: rem(15px);

            ul {
                margin-top: $spacer;
                padding-left: $spacer * 1.25;

                li {
                    line-height: $line-height-sm;
                }
            }
        }
    }

    .how-to-meassure-content {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;

        &.expanded {
            -webkit-line-clamp: unset;
        }
    }
}

#mobileZoomImageCarouselModal,
.product-details {

    .slick-next,
    .slick-prev {
        background-color: rgba(255, 255, 255, .9);
        border-radius: 50%;
        height: rem(45px);
        top: 45%;
        width: rem(45px);
        z-index: 1;

        &::before {
            content: '';
            display: inline-block;
            border-color: $black;
            border-style: solid;
            border-width: rem(3px) rem(3px) 0 0;
            position: relative;
            width: rem(16px);
            height: rem(16px);
        }
    }

    .slick-prev {
        left: rem(16px);

        &::before {
            left: 3px;
            transform: rotate(-135deg);
        }
    }

    .slick-next {
        right: rem(16px);

        &::before {
            right: 3px;
            transform: rotate(45deg);
        }
    }

    .slick-dots {
        left: 0;
        line-height: 0;
        bottom: rem(-6px);

        li {
            margin: 0 rem(2px);
            width: rem(24px);
            height: rem(6px);

            button {
                height: rem(6px);
                width: rem(24px);
                padding: 0;

                &::before {
                    content: '';
                    background-color: #ababab;
                    height: rem(3px);
                    top: rem(2px);
                    width: rem(24px);
                    opacity: 1;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        background-color: $black;
                    }
                }
            }
        }
    }
}

.EmbSelection {
    .form-check {
        position: relative;
        display: block;
        margin-top: $spacer;
        padding-left: 1.75rem;
        line-height: $custom-control-indicator-size;

        label.form-check-label {
            color: $custom-control-label-color;
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            letter-spacing: 0;
            line-height: $custom-control-indicator-size;
            position: relative;
            text-transform: none;
        }
    }

    small {
        color: $gray-800;
        font-size: $font-size-xxs;
        line-height: $line-height-sm;
    }

    .embroidery-preview {
        display: grid;
        gap: rem(8px);
        grid-template-columns: repeat(4, calc((100% - rem(24px)) / 4));
        padding-top: $spacer / 2;

        .embroidery-preview-item[role="button"] {
            cursor: zoom-in;
        }

        .embroidery-preview-label {
            display: block;
            margin-bottom: $spacer / 4;

            @include media-breakpoint-down(xs) {
                width: min-content;
                margin: auto auto 0.25rem;
            }
        }

        .embroidery-preview-content {
            align-items: center;
            border-radius: $border-radius-xs;
            display: flex;
            justify-content: center;
            overflow: hidden;
            flex-flow: row wrap;
            aspect-ratio: 1 / 1;

            >div {
                max-width: calc(98% - 1px);
                overflow: hidden;
            }

            >img {
                height: 100%;
                object-fit: contain;
            }

            >img.mixed {
                max-height: calc(100% / var(--embroidery-item-count, 1));
                object-fit: contain;

                @if var(--embroidery-item-count, 1)==1 {
                    width: 100%;
                }

                @else {
                    width: auto;
                    max-width: initial;
                }
            }

            .embroidery-text {
                line-height: $line-height-sm;
            }

            .embroidery-text,
            input[type=text] {
                display: block;
                padding: 0 0.15rem;
                font-size: $font-size-xs;
                text-align: center;
            }

            input[type=text] {
                font-weight: 400;
                color: var(--embroidery-text-color, $gray-800);
                background-color: $trans;
                background-clip: padding-box;
                border: none;
                box-shadow: none;
                max-width: 100%;
                transition: none;

                &::placeholder {
                    color: var(--embroidery-text-color, $gray-800);
                }

                &:hover,
                &:focus,
                &:active {
                    border: none;
                    box-shadow: none;
                    outline: none;
                }

                &:focus {
                    &::placeholder {
                        opacity: 0;
                    }
                }

                &:-webkit-autofill,
                &:-webkit-autofill:focus {
                    background-color: $trans !important;
                    background-image: none !important;
                    box-shadow: 0 0 0 1000px var(--embroidery-tile-color, $white) inset;
                    -webkit-text-fill-color: var(--embroidery-text-color, $gray-800) !important;
                }
            }

            &.swatch-white {
                border: solid 1px $gray-500;
            }
        }
    }
}

.pbeSelection {
    .pbe-line {
        +.pbe-line {
            margin-top: map-get($spacers, 1);
        }

        p {
            color: $black;
            line-height: $line-height-sm;
            margin-bottom: 0;
        }
    }
}

.custome-pbeSelector {
    .custom-select {
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .custom-select:after {
        content: $custom-select-indicator;
        padding: rem(11px);
        position: absolute;
        right: 0;
        background: $white;
        top: 0;
    }
}

.modal-add-to-cart {
    max-width: rem(890px);

    @include media-breakpoint-down(md) {
        margin: 0;
    }

    @include media-breakpoint-down(md) {
        margin: 0;
    }

    @include media-breakpoint-down(md) {
        margin: 0;
    }

    @include media-breakpoint-down(md) {
        margin: 0;
    }

    .modal-body {
        padding-bottom: $spacer * 1.5;
    }

    .product-image {
        width: 40%;

        @include media-breakpoint-up(md) {
            width: 30%;
        }
    }

    .product-details {
        width: 60%;

        @include media-breakpoint-up(md) {
            width: 70%;
        }

        .product-number {
            font-weight: $font-weight-light;
        }

        .product-name {
            font-size: $font-size-xl;
            letter-spacing: 0;

            @include media-breakpoint-up(md) {
                font-size: $font-size-xxl;
            }
        }

        .list-unstyled {
            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }

            li {
                flex: 0 0 50%;
                margin-bottom: 0;

                @include media-breakpoint-down(md) {
                    flex: 0 0 100%;
                    line-height: 1.5;
                }
            }
        }

        .product-number {
            @include media-breakpoint-down(md) {
                font-size: rem(12px);
                text-transform: uppercase;
            }
        }
    }

    .modal-footer {
        justify-content: space-between;

        .btn {
            margin: 0;
            width: calc(50% - ($spacer / 4));
        }

        @include media-breakpoint-up(md) {
            justify-content: center;

            .btn {
                max-width: 100%;
                width: rem(300px);
            }
        }
    }

    .add-to-cart-modal-heading {
        @include media-breakpoint-down(md) {
            font-size: rem(18px);
            margin-bottom: rem(16px);
            font-weight: 700;
        }
    }

    .modal-header {
        @include media-breakpoint-down(md) {
            padding-bottom: rem(16px);
            border-bottom: 1px solid $gray-500;
            margin-bottom: rem(16px);
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        .modal-title {
            display: flex;
            flex-grow: 1;
        }
    }

    @include media-breakpoint-down(md) {
        .modal-header .close {
            left: rem(12px);
            right: auto;
            top: rem(14px);
        }
    }

    .icon[class*=icon-close] {
        width: rem(14px);
        height: rem(14px);
    }

    .modal-footer {
        @include media-breakpoint-down(md) {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .modal-content {
        @include media-breakpoint-down(md) {
            border-radius: 0;
            border-width: 0;
        }
    }
}

.embroidery-preview-item {
    * {
        pointer-events: none;

        b {
            white-space: nowrap;
            font-size: rem(10px);

            @include media-breakpoint-up(sm) {
                font-size: rem(16px);
            }
        }

        input[type=text] {
            pointer-events: auto;
        }
    }
}

.modal-embroidery-item {
    @include media-breakpoint-up(sm) {
        max-width: rem(500px);
    }

    .embroidery-preview-item {
        cursor: default;
    }

    .embroidery-preview-content {
        align-items: center;
        border-radius: $border-radius-xs;
        display: flex;
        justify-content: center;
        overflow: hidden;
        text-align: center;
        flex-flow: row wrap;
        aspect-ratio: 1 / 1;

        >div {
            max-width: 98%;
            overflow: hidden;
        }

        >img {
            height: 100%;
            object-fit: contain;
        }

        >img.mixed {
            max-height: calc(100% / var(--embroidery-item-count, 1));
            object-fit: contain;

            @if var(--embroidery-item-count, 1)==1 {
                width: 100%;
            }

            @else {
                width: auto;
                max-width: initial;
            }

        }

        >img.mixed.preview {
            width: 100% !important;
        }

        .embroidery-text,
        input[type=text] {
            display: block;
            font-size: $font-size-base * 1.5;
            font-weight: 800;
            text-align: center;
            max-width: 100%;
        }

        input[type=text] {
            pointer-events: none;
            padding: 0;
            color: var(--embroidery-text-color, $gray-800);
            background-color: $trans;
            background-clip: padding-box;
            border: none;
            box-shadow: none;
            transition: none;

            &::placeholder {
                color: var(--embroidery-text-color, $gray-800);
            }

            &:hover,
            &:focus,
            &:active {
                border: none;
                box-shadow: none;
                outline: none;
            }
        }

        img {
            ~input[type=text] {
                margin-top: rem(4px);
            }
        }
    }
}

.add-to-cart-sticky {
    position: fixed;
    padding: rem(16px);
    z-index: 5;
    width: 100%;
    left: 0;
    bottom: 0;
    background: $white;
    border-top: rem(2px) solid $gray-500;
}

#mobileZoomImageCarouselModal {
    &.modal-dialog {
        margin: 0;
        max-width: unset;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .modal-content {
            border: none;
            border-radius: 0;
            height: 100%;

            .modal-header {
                height: 0;
                padding: 0;

                .close {
                    height: rem(45px);
                    right: 0;
                    top: 0;
                    width: rem(45px);

                    .icon {
                        margin: auto;
                    }
                }
            }

            .modal-body {
                padding: 0;

                .slick-list,
                .slick-track,
                .slick-slide,
                .slick-slide>div,
                .zoom-slide {
                    height: 100%;
                }

                .zoom-slide {
                    display: block !important;

                    [id*="image-slide-"] {
                        background-color: $gray-400;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }

    .slick-prev,
    .slick-next {
        top: auto;
        bottom: rem(50px);
    }

    .slick-prev {
        left: calc(50% - #{rem(75px)});
    }

    .slick-next {
        right: calc(50% - #{rem(75px)});
    }
}