.order-details-page {

  .shipping-billing-summary,
  .order-details-data {
    color: $dark;
  }

  .order-details-title {
    @include media-breakpoint-down(md) {
      font-size: $h6-font-size;
    }
  }

  .lineitem-actions {
    display: none !important;
    pointer-events: none;
  }

  .digitization-line-item {
    .line-item-style {
      font-size: rem(12px);

      @include media-breakpoint-up(md) {
        font-size: rem(14px);
      }
    }
  }

  .sorted-shipment-subtitle {
    font-size: rem(16px);
    font-weight: $font-weight-bold;
  }

  .sorted-shipment-subtitle-date {
    color: $gray-800;
    font-weight: $font-weight-normal;
  }

  .back-order-product {
    .buy-again-link {
      display: none;
    }
  }

  .cart-card {
    border-radius: rem(6px);
    border: rem(1px) solid $cart-grey;
    padding: rem(20px) rem(11px);

    @include media-breakpoint-up(md) {
      padding: rem(17px) rem(20px);
    }

    .cart-card__header {
      line-height: 1;

      &.cart-card__header--large {
        font-size: 1.375rem;
        font-weight: 600;
        border-bottom: rem(1px) solid $cart-grey;
      }

      .product-header {
        width: 100px;
        margin-right: 10px;

        img {
          max-width: 100%;
        }

        @include media-breakpoint-up(llg) {
          width: 160px;
          margin-right: 13px;
        }
      }
    }

    .cart-card__body {
      padding: 20px 0;

      .product-line-item {
        border-top: 1px solid #c1c1c1;
        margin-top: 17px;
        padding-top: 17px;

        @include media-breakpoint-down(sm) {
          &:first-of-type {
            border-top-width: 0;
            margin-top: 0;
            padding-top: 0;
          }
        }        

        .product-line-item-details {
          overflow-y: auto;

          .line-item-price-info {
            font-size: .875rem;
            text-transform: uppercase;
            font-weight: 800;
            color: #000;
            letter-spacing: 1.4px;
          }
        }

        .embroidery-lineitem-summary {
          @include media-breakpoint-up(llg) {
            margin-top: -1.6875rem;
          }
        }

        .lineitem-attributes-column {
          position: relative;

          @include media-breakpoint-up(md) {
            padding-bottom: 2.1875rem;
          }
        }

        .line-item-name {
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.38;

          @include media-breakpoint-up(md) {
            font-size: 1.125rem;
            line-height: 1.33;
          }

          .line-item-style {
            display: block;
            color: #404040;
            font-size: .875rem;
            line-height: 1.5;
            font-weight: 400;
            margin-bottom: 15px;
          }
        }

        .item-image {
          background-color: #f6f6f6;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100px;
          margin-right: 10px;

          img {
            max-width: 100%;
          }

          @include media-breakpoint-up(llg) {
            width: 160px;
            margin-right: 13px;
          }
        }

        .line-item-attributes {
          font-size: .875rem;
          color: #404040;
          margin: 0;

          span {
            text-transform: uppercase;
            font-weight: 800;
            color: #000;
            padding-right: 3px;
            letter-spacing: 1.4px;
          }
        }
      }
    }

    .shipping-billing-summary {
      color: #404040;

      .multi-ship .confirm-details .single-shipping,
      .multi-shipping {
        display: none;
      }

    }
  }

  .sidebar-card {
    background-color: #f6f6f6;
    border-radius: 6px;
    padding: 18px 20px;
    color: #404040;

    .card-header-custom {
      font-size: 1.375rem;
      font-weight: 600;
      color: #000;
      line-height: 1;
    }

    .sidebar-card__body {
      border-top: 1px solid #c1c1c1;
      margin-top: 15px;
      padding-top: 15px;

      .order-discount {
        font-size: 1rem;
        font-weight: 600;

        p {
          color: #d0011b !important;
        }

      }

      .shipping-discount {
        color: #d0011b;
      }

      .hide-order-discount,
      .hide-shipping-discount {
        display: none !important;
      }

      .order-summary-costs-list {
        p {
          line-height: 20px;
          margin-bottom: 10px;
        }
      }

      .estimate-total {
        border-top: 1px solid #c1c1c1;
        border-bottom: 1px solid #c1c1c1;
        color: #000;
        font-size: 1.125rem;
        font-weight: 700;
        margin-top: 17px;
        margin-bottom: 17px;
        padding: 11px 0;

        .order-receipt-label {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .order-savings {
        font-size: 1.125rem;
        font-weight: 700;
        color: #d0011b;
      }
    }
  }

  .card {
    border-radius: 0.5rem;
  }

  .card-font-1 {
    font-size: 1rem;
  }

  .card-ul {
    list-style: none;
    padding: 0;
    margin: 0;

    :first-child {
      @include media-breakpoint-down(sm) {
        border-top: none !important;
        padding-top: 0 !important;
        margin-top: 0 !important;
      }
    }
  }

  .product-img {
    background-color: $gray-400;

    img {
      width: 100%;
      height: auto;
    }
  }

  .font-weight-bold {
    font-weight: bold;
  }

  .card-link {
    color: $label-color;

    a {
      color: $label-color;

      &:hover {
        color: $label-color;
      }

      &:active {
        color: $label-color;
      }

      &:focus {
        color: $label-color;
      }
    }

    &.underline-link {
      a {
        text-decoration: underline;
      }
    }
  }

  .font-size-p8 {
    font-size: .8rem;
  }

  .font-size-p875 {
    font-size: .875rem;
  }

  .word-break {
    word-break: break-word;
  }


  .sidebar-card {
    background-color: $gray-400;
    border-radius: 0.5rem;
    padding: rem(18px) rem(20px);
    color: $label-color;
  }

  .card-header-custom {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1;
  }

  .sidebar-card__body {
    border-top: rem(1px) solid $cart-grey;
    margin-top: rem(15px);
    padding-top: rem(15px);
  }

  .icon {
    .icon-embroideryNeedle {
      background-image: $svg-embroideryNeedle;
    }
  }

  .w-m-auto {
    @include media-breakpoint-up(md) {
      width: auto !important;
    }
  }



  .tooltip-box {
    .tooltip-icon {
      background-image: $svg-tooltip;
      height: rem(18px);
      width: rem(18px);
      display: inline-block;
      cursor: pointer;

      .fade {
        display: none;
      }

      &:hover+.fade.tooltip {
        opacity: 1 !important;
        display: block;
      }
    }

    .tooltip {
      top: rem(-100px);
      left: rem(98px);

      .arrow {
        transform: rotate(-45deg);
        bottom: rem(-10px);
        left: rem(75px);
        z-index: 1;

        &::before {
          box-shadow: -0.1875rem 0.1875rem 0.5625rem 0 rgba(0, 0, 0, 0.15);
        }
      }

      .arrow,
      .arrow::before {
        background: #fff;
        width: 1.25rem;
        height: 1.25rem;
      }

      .tooltip-inner {
        text-align: left;
      }
    }
  }

  .divider-top {
    margin-top: rem(-3px);
  }

  .embroidery_details {
    font-size: .875rem;
    margin-top: 15px;
    cursor: pointer;

    .collapse_div {
      display: flex;
      align-items: center;

      &::after {
        background-image: $svg-arrow-up;
      }
    }

    .expand_div {
      display: none;

      &::after {
        background-image: $svg-arrow-down;
      }
    }

    .collapse_div,
    .expand_div {
      &::after {
        content: "";
        display: block;
        display: block;
        height: 6px;
        width: 10px;
        margin-left: 5px;
        background-repeat: no-repeat;
      }

      span {
        text-decoration: underline;
      }
    }

    &.active {
      .expand_div {
        display: flex;
        align-items: center;
      }

      .collapse_div {
        display: none;
      }
    }
  }

  .cart-order-summary {
    .order-summary-costs-list {
      p {
        line-height: 20px;
        margin-bottom: 10px;
      }
    }

    .estimate-total {
      border-top: 1px solid #c1c1c1;
      border-bottom: 1px solid #c1c1c1;
      color: #000;
      font-size: 1.125rem;
      font-weight: 700;
      margin-top: 17px;
      margin-bottom: 17px;
      padding: 11px 0;
    }

  }

  .links-wrapper {
    a {
      font-size: 12px;
      text-decoration: underline;
      margin: 12px;
    }
  }
}